<script setup>
import { ref, onBeforeMount, computed } from 'vue'
import { setRetreaverCampaign } from '@/js/utils'

onBeforeMount(() => {
  setRetreaverCampaign()
})

const props = defineProps({
  showRetreaverNumber: {
    type: Boolean,
    default: false,
    required: false
  },
  retreaverNumber: {
    type: String,
    required: false
  },
  retreaverNumberFormatted: {
    type: String,
    required: false
  },
  backgroundColor: {
    type: String,
    required: false,
    default: '#fff'
  },
  showMVAStyleLogo: {
    type: Boolean,
    required: false,
    default: false
  }
})

const retreaver = ref({
  number: props.retreaverNumber || '+18778151666',
  number_formatted: props.retreaverNumberFormatted || '(877) 815-1666',
})

let changeLogo = false
const urlParams = new URLSearchParams(location.search)
if (urlParams.get('s') === 'aat') changeLogo = true

const getLogoFileString = computed(() => props.showMVAStyleLogo ? 'mlh-logo-red-removed-bg.png' : (changeLogo ? 'mlh-logo-red-removed-bg.png' : 'mlh-logo-2.jpg'))

const logoLink = computed(() => {
  const path = window.location.pathname;

  if (path === '/legal' || path === '/legal/') return '/legal'

  return '/'
});
</script>

<template>
  <nav class="nav" :style="{backgroundColor: backgroundColor}">
    <div class="nav-container">
      <div class="box left">
        <a :href="logoLink">
          <img class="logo" :src="require(`../assets/img/logo/${getLogoFileString}`)" alt="Logo">
        </a>
      </div>
      <div
        :class="{'show-retreaver' : showRetreaverNumber}"
        class="box right"
      >
        <a :href="`tel:${retreaver.number}`">
          <button
            class="phone-number"
          >
            {{ retreaver.number_formatted }}
          </button>
        </a>
      </div>
    </div>
  </nav>
</template>

<style scoped lang="scss">
  @import "../assets/style/constants";

  .nav {
    width: 100%;
    height: 70px;
    border-bottom: 2px solid rgb(237, 237, 237);
    position: fixed;
    top: 0;
    z-index: 100;
    box-shadow: 0 -2px 24px 0 rgba(0,0,0,0.4);
    -webkit-box-shadow: 0 -2px 24px 0 rgba(0,0,0,0.6);
    -moz-box-shadow: 0 -2px 24px 0 rgba(0,0,0,0.6);
    .nav-container {
      max-width: 1200px;
      height: 100%;
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      margin: auto;
      padding: 0 20px;
      .box  {
        width: 100%;
        margin: auto 10px;
        position: relative;
        a {
          .logo {
            max-width: 225px;
            width: 100%;
          }
        }
        &.right {
          text-align: right;
          display: none;
        }

        &.show-retreaver {
          display: block;
        }
        .phone-number {
          clip-path: polygon(5% 0%, 100% 0%, 95% 100%, 0% 100%);
          color: white;
          font-size: 1rem;
          font-family: Arial, sans-serif;
          background-color: #f26e22;
          border: none;
          padding: 14px 10px 10px 10px;
          cursor: pointer;
          &:hover {
            background-color: $primary-hover-color;
          }
        }
      }
    }
  }

@media (max-width: 530px) {
  .nav .nav-container {
    align-items: center;
    .box {
      margin: 0;
      &.left {
        padding: 5px 0 0 0;
      }
      &.right {
        margin: 10px;
        width: 50%;
        a button {
          font-size: 15px;
          min-width: 123px;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .box {
    text-align: center;
    &.right {
      display: none;
    }
  }
}
</style>
