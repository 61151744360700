import { createRouter, createWebHistory } from "vue-router";
import PreSettlementFundingPage from '@/pages/PreSettlementFundingPage.vue'
const HomeWithMultipleRoutes = () => import(/* webpackChunkName: 'HomeWithMultipleRoutes' */ '@/pages/HomeWithMultipleRoutes')
const Home = () => import(/* webpackChunkName: "Home" */ "@/pages/Home")

const LegalListical = () => import(/* webpackChunkName: "LegalListical" */ "@/pages/LegalListical")
const Hernia = () => import(/* webpackChunkName: "Hernia" */ "@/pages/Hernia")
const Talcum = () => import(/* webpackChunkName: "Talcum" */ "@/pages/Talcum")
const Roundup = () => import(/* webpackChunkName: "Roundup" */ "@/pages/Roundup")
const RoundupPath = () => import(/* webpackChunkName: "RoundupPath" */ "@/components/Paths/Roundup/RoundupPath")
const Zantac = () => import(/* webpackChunkName: "Zantac" */ "@/pages/Zantac")
const PageNotFound = () => import(/* webpackChunkName: "PageNotFound" */ "@/pages/PageNotFound")
const Disqualify = () => import(/* webpackChunkName: "Disqualify" */ "@/pages/Disqualify")
const DisqualifyV2 = () => import(/* webpackChunkName: "DisqualifyV2" */ "@/pages/DisqualifyV2")
const Privacy = () => import(/* webpackChunkName: "Privacy" */ "@/pages/Privacy")
const Partners = () => import(/* webpackChunkName: "Partners" */ "@/pages/Partners")
const Terms = () => import(/* webpackChunkName: "Terms" */ "@/pages/Terms")
const ThankYou = () => import(/* webpackChunkName: "ThankYou" */ "@/pages/ThankYou")
const ThankYouV2 = () => import(/* webpackChunkName: "ThankYouV2" */ "@/pages/ThankYouV2")
const ThankYouCampLejeune = () => import(/* webpackChunkName: "ThankYouCampLejeune" */ "@/pages/ThankYouCampLejeune")
const ThankYouMultiMVA = () => import(/* webpackChunkName: "ThankYouMultiMVA" */ "@/pages/ThankYouMultiMVA")
const ThankYouSSDI = () => import(/* webpackChunkName: "ThankYouSSDI" */ "@/pages/ThankYouSSDI")

const ThankYouDUI = () => import(/* webpackChunkName: "ThankYouDUI" */ "@/pages/ThankYouDUI")
const ThankYouPreSettlementFunding = () => import(/* webpackChunkName: "ThankYouPreSettlementFunding" */ "@/pages/ThankYouPreSettlementFund.vue")
const DisqualifyPreSettlementFunding = () => import(/* webpackChunkName: "DisqualifyPreSettlementFunding" */ "@/pages/DisqualifyPreSettlementFundPage.vue")

const WorkersCompPathV2 = () => import(/* webpackChunkName: "WorkersCompPathV2" */ "@/components/Paths/WorkersCompV2/WorkersCompPath")
const WorkersCompSMSVerify = () => import(/* webpackChunkName: "WorkersCompSMSVerify" */ "@/components/Paths/WorkersCompSMSVerify/WorkersCompSMSRoot")
const WorkersCompPathV3 = () => import(/* webpackChunkName: "WorkersCompPathV3" */ "@/components/Paths/WorkersCompV3/WorkersCompPathV3")
const WorkersCompMulti = () => import(/* webpackChunkName: "WorkersCompMultiPath" */ "@/components/Paths/WorkersCompMulti/WorkersCompMultiPath")

const SSDI_Claim = () => import(/* webpackChunkName: "SSDI" */ "@/pages/SSDI_Claim")
const CampLejeune = () => import(/* webpackChunkName: "CampLejeune" */ "@/pages/CampLejeune")
const CampLejeuneV2 = () => import(/* webpackChunkName: "CampLejeuneV2" */ "@/pages/CampLejeuneV2")
const CampLejeuneV2SMS = () => import(/* webpackChunkName: "CampLejeuneV2SMS" */ "@/pages/CampLejeuneV2SMS")
const CampLejeuneSettlement = () => import(/* webpackChunkName: "CampLejeuneSettlement" */ "@/pages/CampLejeuneSettlement")
const StormDamageSingleApp = () => import(/* webpackChunkName: "StormDamage" */ "@/pages/StormDamageSingleApp")
const StormDamageFlowApp = () => import((/* webpackChunkName: "StormDamageFlowApp" */ "@/pages/StormDamageFlowApp"))
const StormDamageThankYouPage = () => import((/* webpackChunkName: "StormDamageThankYouPage" */ "@/pages/StormDamageThankYouPage"))
const CampLejeuneToxicWater = () => import(/* webpackChunkName: "CampLejeuneToxicWater" */ "@/pages/CampLejeuneToxicWater")
const DisqualifiedLastPage = () => import(/* webpackChunkName: 'DisqualifiedLastPage' */ '@/pages/DisqualifiedLastPage')
const MVA = () => import(/* webpackChunkName: "MVA" */ "@/pages/MVA_App")
const DUI = () => import(/* webpackChunkName: "DUI" */ "@/components/Paths/DUI/DUIPath")
const DoNotSellMyInformation = () => import('@/pages/DoNotSellMyInformationView')
const SSDIFlow = () => import (/* webpackChunkName: "SSDIAFlow" */ "@/pages/SSDIFlowPath")
const DentistPage = () => import(/*webpackChunkName: "DentistPage"*/ "@/pages/DentistPage")
const DentistTermsOfService = () => import(/*webpackChunkName: "DentistTermsOfService"*/ "@/components/Dentist/TermsOfUse.vue")
const DentistPrivacyPolicy = () => import(/*webpackChunkName: "DentistPrivacyPolicy"*/ "@/components/Dentist/PrivacyPolicy.vue")

const routes = [
  { path: "/", component: HomeWithMultipleRoutes, meta: { title: "My Lawsuit Help" } },
  { path: "/legal", component: LegalListical, meta: { title: "My Lawsuit Help" } },
  { path: "/hernia", component: Hernia, meta: { title: "Hernia" } },
  { path: "/talcum", component: Talcum, meta: { title: "Talcum" } },
  { path: "/roundup", component: RoundupPath, meta: { title: "Roundup" } },
  { path: "/roundup2", component: RoundupPath, meta: { title: "Roundup"} },
  { path: "/zantac", component: Zantac, meta: { title: "Zantac" } },
  { path: "/disqualify", component: Disqualify, meta: {title: "Disqualify" } },
  { path: "/disqualify-v2", component: DisqualifyV2, meta: {title: "Disqualify" } },
  { path: "/disqualify-psf", component: DisqualifyPreSettlementFunding, meta: {title: "Disqualify" } },
  { path: '/privacy-policy', component: Privacy, meta: {title: 'Privacy' } },
  { path: '/terms-of-use', component: Terms, meta: {title: 'Privacy' } },
  { path: '/partners', component: Partners, meta: {title: 'Partners' } },
  { path: '/thank-you', component: ThankYou, meta: {title: 'ThankYou' } },
  { path: '/thank-you-v2', component: ThankYouV2, meta: { title: 'ThankYou'} },
  { path: '/thank-you-cj', component: ThankYouCampLejeune, meta: { title: 'ThankYou'} },
  { path: '/thank-you-dui', component: ThankYouDUI, meta: { title: 'ThankYou'} },
  { path: '/thank-you-ssdi', component: ThankYouSSDI, meta: { title: 'ThankYou'} },
  { path: '/workers-comp', component: WorkersCompPathV2, meta: {title: 'WorkersComp' } },
  { path: '/workers-comp-vw', component: WorkersCompPathV2, meta: {title: 'WorkersComp' } },
  { path: '/workers-comp-vw2', component: WorkersCompPathV2, meta: {title: 'WorkersComp' } },
  { path: '/workers-comp-vw3', component: WorkersCompPathV2, meta: {title: 'WorkersComp' } },
  { path: '/workers-comp-sms', component: WorkersCompSMSVerify, meta: {title: 'WorkersComp' } },
  { path: '/workers-comp-v3', component: WorkersCompPathV3, meta: {title: 'WorkersComp'} },
  { path: '/wc-multi', component: WorkersCompMulti, meta: {title: 'WorkersComp'} },
  { path: '/ssdi', component: SSDI_Claim, meta: {title: 'SSDI' } },
  { path: '/storm-damage', component: StormDamageSingleApp, meta: {title: 'StormDamageSPA'} },
  { path: '/storm-damage-flow', component: StormDamageFlowApp, meta: {title: 'StormDamageFlow'} },
  { path: '/mva', component: MVA, meta: {title: 'MVA'} },
  { path: '/dui', component: DUI, meta: {title: 'DUI'} },
  { path: '/storm-ty', component: StormDamageThankYouPage, meta: {title: 'ThankYou'} },
  { path: "/camp-lejeune", component: CampLejeune, meta: { title: "Camp Lejeune" } },
  { path: "/camp-lejeune-v2", component: CampLejeuneV2, meta: { title: "Camp Lejeune" } },
  { path: "/camp-lejeune-settlement", component: CampLejeuneSettlement, meta: { title: "Camp Lejeune" } },
  { path: "/camp-lejeune-sms", component: CampLejeuneV2SMS, meta: { title: "Camp Lejeune" } },
  { path: "/camp-lejeune-toxic-water", component: CampLejeuneToxicWater, meta: { title: "Camp Lejeune Toxic Water" } },
  { path: "/tws-v2", component: CampLejeuneToxicWater, meta: { title: "Camp Lejeune Toxic Water" } },
  { path: '/thank-you-dq', component: DisqualifiedLastPage, meta: { title: 'Thank You DQ' } },
  { path: "/:pathMatch(.*)*", component: PageNotFound, meta: { title: "404" } },
  { path: '/thank-you-mva', component: ThankYouMultiMVA, meta: {title: 'ThankYouMultiMVA' } },
  { path: '/do-not-sell-my-information', component: DoNotSellMyInformation, meta: { title: 'Do Not Sell My Information' } },
  { path: "/ssdi-flow", component: SSDIFlow, meta: { title: "SSDI" } },
  { path: "/dentist", component: DentistPage, meta: { title: "Dentist" } },
  { path: "/psf", component: PreSettlementFundingPage, meta: { title: "PreSettlement Funding" } },
  { path: "/thank-you-psf" , component: ThankYouPreSettlementFunding, meta: { title: "Thank You PreSettlement Funding" } },
  { path: "/d/terms-of-use", component: DentistTermsOfService, meta: { title: "Dentist" } },
  { path: "/d/privacy-policy", component: DentistPrivacyPolicy, meta: { title: "Dentist" } },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
