<script setup>
import {ref} from "vue";

const props = defineProps({
  showRetreaverNumber: {
    type: Boolean,
    default: false,
    required: false
  },
  retreaverNumber: {
    type: String,
    required: false
  },
  retreaverNumberFormatted: {
    type: String,
    required: false
  }
})

const retreaver = ref({
  number: props.retreaverNumber || '+18778151666',
  number_formatted: props.retreaverNumberFormatted || '(877) 815-1666',
})
</script>

<template>
  <div class="container">
    <a href="mailto:info@mylawsuithelp.com">info@mylawsuithelp.com</a>
    <div
      :class="{'show-retreaver' : showRetreaverNumber}"
      class="reatrever-box"
    >
      <a :href="`tel:${retreaver.number}`">
        {{ retreaver.number_formatted }}
      </a>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.container {
  margin: auto;
  padding:  60px 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 15px;
  a {
    text-decoration: none;
    color: #147bc9;
    font-size: 16px;
    font-weight: 500;
    &:hover {
      color: #0f5e9a;
    }
  }
  .reatrever-box {
    display: none;
    &.show-retreaver {
      display: block;
    }
  }
}
</style>