<script setup>
import { computed } from 'vue'

const props = defineProps({
  showMVAStyleLogo: {
    type: Boolean,
    required: false,
    default: false
  }
})

let changeLogo = false
const urlParams = new URLSearchParams(location.search)
if (urlParams.get('s') === 'aat') changeLogo = true

const getLogoFileString = computed(() => props.showMVAStyleLogo ? 'mlh-logo-red-removed-bg.png' : (changeLogo ? 'mlh-logo-red-removed-bg.png' : 'mlh-logo-2.jpg'))
</script>

<template>
  <div class="footer">
    <div class="footer-container">
      <div class="footer-wrapper">
        <div class="first-container">
          <img :src="require(`../assets/img/logo/${getLogoFileString}`)" alt="">
          <div class="footer-nav">
            <router-link class="nav-button" title="Privacy Policy" to="/privacy-policy" target="_blank">Privacy Policy</router-link>
            <router-link class="nav-button" title="CA Resident" to="/privacy-policy#california-resident" target="_blank">CA Resident</router-link>
            <router-link class="nav-button" title="Terms of Use" to="/terms-of-use" target="_blank">Terms of Use</router-link>
            <router-link class="nav-button" title="Do Not Sell My Personal Information" to="/do-not-sell-my-information" target="_blank">Do Not Sell My Personal Information</router-link>
          </div>
        </div>
        <div class="second-container">
          <div class="text">
            My Lawsuit Help is a paid advertisement, not an endorsement, recommendation, or referral to any particular law
            firm. We are not a law firm and we do not provide legal advice or evaluate your legal claim. We are a free
            service that connects you with third party law firms to discuss your claims. You are under no obligation to
            engage the services of our participating law firms. My Lawsuit Help relies on your geographic location to
            determine which law firm to connect you with and makes no representation regarding a lawyer’s experience or
            skills.
            <br><br>
            Copyright © {{ new Date().getFullYear() }} My Lawsuit Help | All rights reserved.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "../assets/style/style";

.footer {
  background-color: #fff;
  z-index: 2;
  -webkit-box-shadow: 0 -2px 24px 0 rgb(0 0 0 / 30%);
  .footer-container {
    max-width: 1200px;
    margin: auto;
    text-align: center;
    .footer-wrapper {
      width: 100%;
      max-width: 1000px;
      margin: 0 auto;
      padding: 0 10px;
      .first-container {
        border-bottom: 2px solid $secondary-color;
        display: flex;
        justify-content: space-between;
        padding: 20px 0;
        img {
          max-width: 240px;
          width: 100%;
        }
        .footer-nav {
          display: flex;
          align-items: center;
          .nav-button {
            margin: 0 20px;
            color: $secondary-color;
            text-decoration: none;
            font-family: Arial, sans-serif;
            &:hover {
              color: $primary-color;
            }
          }
        }
      }
      .second-container {
        .text {
          color: $secondary-color;
          font-family: Arial, sans-serif;
          font-weight: 400;
          margin: 20px auto 0 auto;
          padding-bottom: 20px;
          font-size: 0.875rem;
          line-height: 1.5rem;
          max-width: 90%;
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .footer .footer-container .footer-wrapper .first-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 620px) {
  .footer .container .footer-wrapper .first-container {
    display: block;
    .footer-nav {
      padding: 0 20px 20px 20px;
    }
  }
}

@media (max-width: 620px) {
  .footer .footer-container .footer-wrapper .first-container {
    display: inline-block;
    padding: 15px;
    .footer-nav {
      display: flex;
      flex-direction: column;
      .nav-button {
        padding: 10px;
      }
    }
  }
}
</style>
