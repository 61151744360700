<script setup>
  // const hidePrivacyGuaranteeTextOnDefaultRoute = window.location.pathname === '/workers-comp' || window.location.pathname === '/workers-comp/'
  const hidePrivacyGuaranteeTextOnDefaultRoute = false
</script>

<template>
  <div :class="{ hide: hidePrivacyGuaranteeTextOnDefaultRoute }" class="container">
    <p>
      <span>
        <img src="../../../assets/img/lock.svg" alt="lock">
      </span>
      Inquiries are <strong>100% Secure & Confidential</strong>
    </p>
  </div>
</template>

<style lang="scss" scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: baseline;
  margin: 5px 0 15px 0px;
  &.hide {
    display: none;
  }
  p {
    text-align: center;
    img {
      filter: invert(92%) sepia(0%) saturate(280%) hue-rotate(185deg) brightness(95%) contrast(91%);
      height: auto;
      width: 15px;
    }
    color: #808994;
    display: inline;
    font-size: 12px;
    margin-left: 5px;
    font-weight: normal;
  }
}
</style>
