<script setup>
import { useStore } from 'vuex'
import { onMounted } from 'vue'
import SecureDisclaimer from '@/components/Paths/SharedComponents/SecureDisclaimer.vue'

const emits = defineEmits(['next-step'])

const store = useStore()

const question = 'Have you already received funding for your case?'
const stepName = 'ReceivedFunding'

const ANSWERS = [
  {
    label: 'No',
    values: [
      { field: 'received_funding', value: 'no' }
    ]
  },
  {
    label: 'Yes',
    values: [
      { field: 'received_funding', value: 'yes' }
    ]
  }
]

function setAnswer(answer) {
  store.dispatch('saveUserProgressionAndBackup', {
    userProgression: {
      step_number: 2,
      question,
      answer: answer[0].value
    },
    userBackup: {
      with_Lawyer: answer[0].value,
    }
  }).then()

  store.dispatch('setAnswers', answer)
  emits('next-step', stepName)
}

onMounted(() => {
  store.commit('SET_IS_LOADING', false, { root: true })
  store.commit('SET_MIN_HEIGHT_OF_THE_LAST_COMPONENT', document.getElementById('component-height').clientHeight)
})
</script>

<template>
  <div id="component-height" class="flow-container">
    <slot></slot>

    <div class="row">
      <div class="box">
        <h3 class="header-title">{{ question }}</h3>
      </div>
    </div>

    <div class="row">
      <div class="box">
        <div class="next-button-container-flex">
          <button
            v-for="answer in ANSWERS"
            class="default-button alternate-button-color"
            @click="setAnswer(answer.values)"
          >
            {{ answer.label }}
          </button>
        </div>
      </div>
      <secure-disclaimer />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/style/flow-style";
.aat-style .flow-container .row .box .next-button-container-flex .default-button {
  padding: 16px 10px;
  max-width: 350px;
}
</style>