<script setup>
</script>

<template>
  <div class="tcpa-flow-container">
    <label for="">
      <input type="hidden" id="leadid_tcpa_disclosure"/>
      <p class="compliance-text">
        By clicking "Submit", I provide my express written consent via electronic
        signature authorizing My Lawsuit Help and
        <a href="/partners" target="_blank">one law firm, their agents or marketing partners</a>
        to contact me about my case and other related
        legal products and services to the number and email address I provided
        (including any wireless number). I further expressly consent to receive
        telemarketing emails, calls, text messages, pre-recorded messages, and
        artificial voice messages via an autodialed phone system, even if my telephone
        number is a mobile number that is currently listed on any state, federal or
        corporate “Do Not Call” list.
        You agree that we may contact you anytime, including before 8am or after 9pm local time. I understand that my consent is not a condition
        of purchase of any goods or services and that standard message and data rates
        may apply.
        <br/>
        <br/>
        By Clicking "Submit" I also confirm I have read and agreed to this website's
        <a href="/terms-of-use" target="_blank">Terms of Service</a> and <a href="/privacy-policy" target="_blank">Privacy Policy</a>,
        which includes a mandatory arbitration provision. For California residents see
        <a href="/privacy-policy/#ca-privacy-rights" target="_blank">here</a>.
      </p>
    </label>
  </div>
  <div class="video-container">
    <video src="../../../../assets/claims-video.mp4" controls="" poster="../../../../assets/img/poster.png" preload="none" style="width: 100%;outline: none;"></video>
  </div>
</template>

<style scoped lang="scss">
.tcpa-flow-container {
  display: flex;
  align-items: center;
  .compliance-text {
    font-size: 12pt;
    font-family: 'Osawld', sans-serif;
    color: black;
    font-weight: 400;
    text-align: justify;
    a {
      text-decoration: none;
      color: #147bc9;
      &:hover {
        color: #0f5e9a;
      }
    }
  }
}
.video-container {
  padding: 15px;
}
</style>
