<script setup>
import { useStore } from 'vuex'
import { maska as vMaska } from 'maska'
import { reactive, ref, computed } from 'vue'
import { ipqsIsEmailInvalid, goToThankYouPage, goToDisqualify } from '@/js/utils'
import { EMAIL_REGEX, internalZipLookup, ipqsCheckIfPhoneIsValid2, extractUrlParams } from '@/js/utils'

import WorkersCompTCPA from '@/components/Paths/WorkersCompV2/WorkersCompComponents/WorkersCompTCPA.vue'
import SecureDisclaimer from '@/components/Paths/SharedComponents/SecureDisclaimer.vue'
import axios from 'axios'

const stepName = 'UserPii'
const emits = defineEmits(['next-step'])
const store = useStore()

const data = reactive({
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  zip: '',
  state: '',
  state_long: '',
  city: '',
  county: ''
})

const loading = ref(false)
const firstNameError = ref(false)
const lastNameError = ref(false)
const emailError = ref(false)
const phoneError = ref(false)
const zipError = ref(false)

const buttonText = computed(() => {
  return loading.value ? 'Loading...' : 'Submit'
})

const hasErrors = computed(() => {
  return firstNameError.value || lastNameError.value || emailError.value || phoneError.value || zipError.value
})

function validateNames() {
  firstNameError.value = data.firstName.length <= 3
  lastNameError.value = data.lastName.length <= 3
}

async function validateEmail() {
  if (!data.email) {
    emailError.value = true
    return
  }

  const validFormat = EMAIL_REGEX.test(String(data.email).toLowerCase())
  if (!validFormat) {
    emailError.value = true
    return
  }

  emailError.value = await ipqsIsEmailInvalid(data.email)
}

async function validatePhone() {
  if (!data.phone || data.phone.length < 14) {
    phoneError.value = true
    return
  }

  const { fullValid } = await ipqsCheckIfPhoneIsValid2(data.phone)
  phoneError.value = !fullValid
}

async function validateZip() {
  if (!data.zip || data.zip.length < 5) {
    zipError.value = true
    return
  }

  const z = await internalZipLookup(data.zip)
  if (z.zipError) {
    zipError.value = true
    return
  }

  data.state_long = z.state_long
  data.city = z.city
  data.county = z.county
  data.state = z.stateCode
  zipError.value = false
}

// async function goToThankYouPage(affid, subId, page, sub_id1) {
//   const searchParams = appendMissingParams({
//     'affid' : affid,
//     'sub_id' : subId,
//     'page': page,
//     'sub1,': sub_id1,
//   })
//
//   window.location.href = '/thank-you-psf?' + searchParams
// }
//
// async function goToDisqualify(affid, subId, reason) {
//   const searchParams = appendMissingParams({
//     'affid' : affid,
//     'sub_id' : subId,
//     'reason': reason
//   })
//
//   window.location.href = '/disqualify-psf?' + searchParams
// }

async function sendData() {
  const dto = {
    first_name: store.state.fields.first_name,
    last_name: store.state.fields.last_name,
    email: store.state.fields.email,
    phone: store.state.fields.phone.replace(/[()\-\s]+/g, ""),
    zip_code: store.state.fields.zip_code,
    city: store.state.fields.city,
    state: store.state.fields.state,
    county: store.state.fields.county,
    trusted_form_cert_id: store.state.fields.trustedFormId,
    trusted_form_cert_url: store.state.fields.trustedFormUrl,
    ip_address: store.state.fields.ipAddress,
    leadid_token: store.state.fields.jornayaId,
    ef_transaction_id: store.state.fields.ef_transaction_id,

    law_firm_name: store.state.fields.lawfirm_name,
    attorney_first_name: store.state.fields.attorney_first_name,
    attorney_last_name: store.state.fields.attorney_last_name,
    law_firm_phone: store.state.fields.attorney_phone_number.replace(/[()\-\s]+/g, ""),
    attorney_email_address: store.state.fields.attorney_email,
    working_with_attorney_at_least_60_days: store.state.fields.working_with_attorney_at_least_60_days,
    received_funding: store.state.fields.received_funding,

    qualified: store.state.fields.qualified,
    oid: store.state.fields.oid,
    affid: store.state.fields.affid || 1,

    ...extractUrlParams()
  }
  const url = 'https://control.org31415.dev/api/leads/ingest'
  await axios.post(url, dto)

  const affid = data.affid || 1
  const sub_id = data.sub_id || ''
  const page = data.page || 'pre-settlement-funding'
  const sub_id1 = data.sub_id1 || ''

  if (dto.qualified === 'no') {
    await goToDisqualify(affid, sub_id, store.state.DQ.reason, '/disqualify-psf?')
  } else {
    await goToThankYouPage(affid, sub_id, page, sub_id1, '/thank-you-psf?')
  }
}

async function setAnswers(answers) {
  store.dispatch('saveUserProgressionAndBackup', {
    userProgression: {
      step_number: 4,
      question: 'User PII',
      answer: 'Added User PII'
    },
    userBackup: {
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.email,
      phone: data.phone,
      zip: data.zip,
      city: data.city,
      state: data.state,
      state_long: data.state_long,
      county: data.county,
    }
  }).then()

  await store.dispatch('setAnswers', answers)
  await sendData()
}

async function onSubmit() {
  loading.value = true
  // run validations
  validateNames()

  await Promise.allSettled([
    await validateEmail(),
    await validatePhone(),
    await validateZip()
  ])

  if (hasErrors.value) {
    loading.value = false
    return
  }

  // submit data
  const values = [
    { field: 'first_name', value: data.firstName },
    { field: 'last_name', value: data.lastName },
    { field: 'email', value: data.email },
    { field: 'phone', value: data.phone },
    { field: 'zip_code', value: data.zip },
    { field: 'state', value: data.state },
    { field: 'state_long', value: data.state_long },
    { field: 'city', value: data.city },
    { field: 'county', value: data.county },
  ]

  setAnswers(values)
}
</script>

<template>
  <div id="component-height" class="flow-container">
    <div class="row">
      <div class="box">
        <h3 class="header-title first">
          Based on your answers:
        </h3>
        <br/>
        <p class="subtitle">
          The sooner you act the better your chances!
        </p>
      </div>
    </div>

    <div class="row">
      <div class="box">
        <input
          v-model="data.firstName"
          :disabled="loading"
          @input="firstNameError = false"
          :class="{ 'input-error': firstNameError }"
          type="text"
          placeholder="First Name"
          autocomplete="off"
        />

        <div class="error-container">
          <span v-if="firstNameError">
            Please provide a first name
          </span>
        </div>
      </div>

      <div class="box">
        <input
          v-model="data.lastName"
          :disabled="loading"
          @input="lastNameError = false"
          :class="{ 'input-error': lastNameError }"
          type="text"
          placeholder="Last Name"
          autocomplete="off"
        />

        <div class="error-container">
          <span v-if="lastNameError">
            Please provide a last name
          </span>
        </div>
      </div>

      <div class="box">
        <input
          v-model="data.email"
          :disabled="loading"
          @input="emailError = false"
          :class="{ 'input-error': emailError }"
          type="email"
          placeholder="Email"
          autocomplete="off"
        />

        <div class="error-container">
          <span v-if="emailError">
            Please provide a valid email
          </span>
        </div>
      </div>

      <div class="box">
        <input
          v-model="data.phone"
          :disabled="loading"
          @input="phoneError = false"
          :class="{ 'input-error': phoneError }"
          type="text"
          placeholder="Phone Number"
          autocomplete="off"
          v-maska="'(###) ###-####'"
        />

        <div class="error-container">
          <span v-if="phoneError">
            Please provide a valid phone number
          </span>
        </div>
      </div>

      <div class="box">
        <input
          v-model="data.zip"
          :disabled="loading"
          @input="zipError = false"
          :class="{ 'input-error': zipError }"
          type="text"
          placeholder="Zip Code"
          autocomplete="off"
          v-maska="'#####'"
        />

        <div class="error-container">
          <span v-if="zipError">
            Please provide a valid zip code
          </span>
        </div>
      </div>

      <div class="row">
        <div class="box">
          <div class="submit-button-container">
            <button
              @click="onSubmit"
              :disabled="loading"
              class="submit-button"
            >
              {{ buttonText }}
            </button>
          </div>
          <secure-disclaimer/>

          <div class="tcpa-container">
            <WorkersCompTCPA/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/style/flow-style";
.flow-container .row .box {
  margin: 2px 0;
  &:first-child {
    margin: 0 0 2px 0;
  }
  .header-title {
    &.first {
      margin: 0;
    }
    &.second {
      margin: 5px 0 15px 0;
      color: #39b54a;
      font-weight: 500;
    }
  }
  .subtitle {
    font-weight: 500;
    color: #162D59;
    font-size: 22px;
    letter-spacing: 0;
    text-align: center;
    margin-bottom: 20px;
  }
}

.aat-style .flow-container .row .box .submit-button-container .submit-button {
  border-radius: 50px;
}

@media (max-width: 500px) {
  .flow-container .row .box .submit-button-container .submit-button {
    margin: 0 0 16px 0;
  }
}

@media (max-width: 320px) {
  .flow-container {
    padding: 10px;
  }
  .container {
    margin-bottom: 0;
  }
}
</style>