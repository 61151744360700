<script setup>
import { useStore } from 'vuex'
import { pingOnPageLoadWithData } from '@/js/utils'
import { computed, onBeforeMount, onMounted } from 'vue'

import ProgressV2 from '@/components/Paths/SharedComponents/ProgressV2.vue'
import WorkingWithAttorney from '@/components/Paths/PreSettlementFunding/Steps/WorkingWithAttorney.vue'
import ReceivedFunding from '@/components/Paths/PreSettlementFunding/Steps/ReceivedFunding.vue'
import LawFirmInformation from '@/components/Paths/PreSettlementFunding/Steps/LawFirmInformation.vue'
import UserPii from '@/components/Paths/PreSettlementFunding/Steps/UserPii.vue'

const store = useStore()
const minHeight = computed(() => store.state.minHeightOfTheLastComponent)
const currentStep = computed(() => store.state.currentStep)

const STEPS = {
  'WorkingWithAttorney': { component: WorkingWithAttorney },
  'ReceivedFunding': { component: ReceivedFunding },
  'LawFirmInformation': { component: LawFirmInformation },
  'UserPii': { component: UserPii }
}

function goToNextStep(step) {
  store.commit('SET_IS_LOADING', true)
  store.dispatch('preSettlementFunding/goToNextStep', step)
}

onBeforeMount(() => {
  pingOnPageLoadWithData()

  store.dispatch('init')
  store.state.page = window.location.pathname.replace('/', '')

  const oid = '350'
  store.commit('SET_EF_OID', oid)

  goToNextStep('StartPath')
})

onMounted(() => {
  document.body.classList.add('workers-comp')
  document.body.classList.add('aat-style')
})
</script>

<template>
  <div
    :style="{'min-height': minHeight + 'px'}"
    class="content-wrapper"
  >
    <transition
      name="component-fade"
      mode="out-in"
      :duration="30"
    >
      <component
        :is="STEPS[currentStep].component"
        @next-step="goToNextStep"
      >
        <slot name="progress">
          <ProgressV2 :progress="store.state.progress"/>
        </slot>
      </component>
    </transition>
  </div>
</template>