<script setup>
import { useStore } from 'vuex'
import { maska as vMaska } from 'maska'
import { EMAIL_REGEX } from '@/js/utils'
import { reactive, ref, watch } from 'vue'
import SecureDisclaimer from '@/components/Paths/SharedComponents/SecureDisclaimer.vue'

const emits = defineEmits(['next-step'])
const store = useStore()

const stepName = 'LawFirmInformation'

const data = reactive({
  lawFirmName: '',
  attorneyFirstName: '',
  attorneyLastName: '',
  attorneyPhoneNumber: '',
  attorneyEmail: '',
})

const lawFirmNameError = ref(false)
const attorneyFirstNameError = ref(false)
const attorneyLastNameError = ref(false)
const attorneyPhoneNumberError = ref(false)
const attorneyEmailError = ref(false)

function setAnswers(answers) {
  store.dispatch('saveUserProgressionAndBackup', {
    userProgression: {
      step_number: 3,
      question: 'Law Firm Information',
      answer: 'Added Law Firm Information'
    },
    userBackup: {
      lawFirmName: data.lawFirmName,
      attorneyFirstName: data.attorneyFirstName,
      attorneyLastName: data.attorneyLastName,
      attorneyPhoneNumber: data.attorneyPhoneNumber,
      attorneyEmail: data.attorneyEmail
    }
  }).then()

  store.dispatch('setAnswers', answers)
  emits('next-step', stepName)
}

function validate() {
  lawFirmNameError.value = data.lawFirmName.length <= 3
  attorneyFirstNameError.value = data.attorneyFirstName.length <= 3
  attorneyLastNameError.value = data.attorneyLastName.length <= 3
  attorneyPhoneNumberError.value = data.attorneyPhoneNumber.length < 14
  attorneyEmailError.value = !(EMAIL_REGEX.test(String(data.attorneyEmail).toLowerCase()))

  return !lawFirmNameError.value &&
    !attorneyFirstNameError.value &&
    !attorneyLastNameError.value &&
    !attorneyPhoneNumberError.value &&
    !attorneyEmailError.value
}

function onSubmit() {
  if (validate()) {
    const values = [
      { field: 'lawfirm_name', value: data.lawFirmName },
      { field: 'attorney_first_name', value: data.attorneyFirstName },
      { field: 'attorney_last_name', value: data.attorneyLastName },
      { field: 'attorney_phone_number', value: data.attorneyPhoneNumber },
      { field: 'attorney_email', value: data.attorneyEmail }
    ]

    setAnswers(values)
  }
}

watch(
  () => data.lawFirmName,
  () => {
    if (lawFirmNameError.value) {
      lawFirmNameError.value = false
    }
  }
)

watch(
  () => data.attorneyFirstName,
  () => {
    if (attorneyFirstNameError.value) {
      attorneyFirstNameError.value = false
    }
  }
)

watch(
  () => data.attorneyLastName,
  () => {
    if (attorneyLastNameError.value) {
      attorneyLastNameError.value = false
    }
  }
)

watch(
  () => data.attorneyPhoneNumber,
  () => {
    if (attorneyPhoneNumberError.value) {
      attorneyPhoneNumberError.value = false
    }
  }
)

watch(
  () => data.attorneyEmail,
  () => {
    if (attorneyEmailError.value) {
      attorneyEmailError.value = false
    }
  }
)
</script>

<template>
  <div id="component-height" class="flow-container">
    <div class="row">
      <div class="box">
        <h3 class="header-title first">
          Please enter your law firm information:
        </h3>
        <br>
        <p class="subtitle">
          Your accident may be eligible for financial compensation for your medical bills and pain & suffering. The
          sooner you act the better your chances!
        </p>
      </div>
    </div>

    <div class="row">
      <div class="box">
        <input
          v-model="data.lawFirmName"
          :class="{ 'input-error' : lawFirmNameError }"
          placeholder="Law Firm Name"
          type="text"
          autocomplete="off"
        />

        <div class="error-container">
          <span v-if="lawFirmNameError">Please provide a law firm name</span>
        </div>
      </div>

      <div class="box">
        <input
          v-model="data.attorneyFirstName"
          :class="{ 'input-error' : attorneyFirstNameError }"
          type="text"
          placeholder="Attorney First Name"
          autocomplete="off"
        />
        <div class="error-container">
          <span
            v-if="attorneyFirstNameError"
          >
            Please provide an attorney first name
          </span>
        </div>
      </div>

      <div class="box">
        <input
          v-model="data.attorneyLastName"
          :class="{ 'input-error' : attorneyLastNameError }"
          type="text"
          placeholder="Attorney Last Name"
          autocomplete="off"
        />
        <div class="error-container">
          <span
            v-if="attorneyLastNameError"
          >
            Please provide an attorney last name
          </span>
        </div>
      </div>

      <div class="box">
        <input
          v-model="data.attorneyPhoneNumber"
          :class="{ 'input-error' : attorneyPhoneNumberError }"
          type="text"
          placeholder="Attorney Phone Number"
          autocomplete="off"
          v-maska="'(###) ###-####'"
        />
        <div class="error-container">
          <span
            v-if="attorneyPhoneNumberError"
          >
            Please provide an attorney phone number
          </span>
        </div>
      </div>

      <div class="box">
        <input
          v-model="data.attorneyEmail"
          :class="{ 'input-error' : attorneyEmailError }"
          type="text"
          placeholder="Attorney Email"
          autocomplete="off"
        />
        <div class="error-container">
          <span
            v-if="attorneyEmailError"
          >
            Please provide an attorney email
          </span>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="box">
        <div class="submit-button-container">
          <button
            @click="onSubmit"
            class="submit-button"
          >
            Next
          </button>
        </div>
        <secure-disclaimer/>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/style/flow-style";
.flow-container .row .box {
  margin: 2px 0;
  &:first-child {
    margin: 0 0 2px 0;
  }
  .header-title {
    &.first {
      margin: 0;
    }
    &.second {
      margin: 5px 0 15px 0;
      color: #39b54a;
      font-weight: 500;
    }
  }
  .subtitle {
    font-weight: 500;
    color: #162D59;
    font-size: 22px;
    letter-spacing: 0;
    text-align: center;
    margin-bottom: 20px;
  }
}

.aat-style .flow-container .row .box .submit-button-container .submit-button {
  border-radius: 50px;
}

@media (max-width: 500px) {
  .flow-container .row .box .submit-button-container .submit-button {
    margin: 0 0 16px 0;
  }
}

@media (max-width: 320px) {
  .flow-container {
    padding: 10px;
  }
  .container {
    margin-bottom: 0;
  }
}
</style>