import axios from 'axios'

const preSettlementFunding = {
  namespaced: true,
  getters: {
    getQualified: (state, getters, rootState) => {
      return (
        rootState.fields.working_with_attorney_at_least_60_days === 'yes' &&
        rootState.fields.received_funding === 'no'
      )
    }
  },
  actions: {
    async goToNextStep({ rootState, getters, commit, dispatch }, step) {
      // scroll to top of page
      window.scrollTo({top: 0, behavior: 'smooth'});

      let nextStep = '';
      let progress = 0;

      switch (step) {
        case 'StartPath': {
          nextStep = 'WorkingWithAttorney';
          progress = 12;
          break;
        }

        case 'WorkingWithAttorney': {
          nextStep = 'ReceivedFunding';
          progress = 58;
          break;
        }

        case 'ReceivedFunding': {
          nextStep = 'LawFirmInformation';
          progress = 78;
          break;
        }

        case 'LawFirmInformation': {
          commit('SET_FIELD', { field: 'qualified', value: getters.getQualified ? 'yes' : 'no' }, { root: true })
          if (rootState.fields.working_with_attorney_at_least_60_days === 'no') {
            commit('SET_DISQUALIFIED', { reason: 'not_working_with_attorney_at_least_60_days'}, { root: true })
          }

          if (rootState.fields.received_funding === 'yes') {
            commit('SET_DISQUALIFIED', { reason: 'received_funding'}, { root: true })
          }
          nextStep = 'UserPii';
          progress = 98;
          break;
        }

        case 'UserPii': {
          progress = 100;
          break;
        }
      }

      commit('SET_CURRENT_PROGRESS', progress, { root: true });
      commit('SET_CURRENT_STEP', nextStep, { root: true });
    }
  }
}

export default preSettlementFunding;